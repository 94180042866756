<template>
  <div
    class="mx-auto"
  >
    <b-alert
      variant="success"
      show
      class="d-flex justify-content-center p-2 mx-auto"
    >
      <p class="mx-auto">
        You will be redirected shortly
      </p>
    </b-alert>
  </div>
</template>

<script>
export default {

  data() {
    return {
      redirectionId: this.$route.params.redirection_id,
    }
  },
  watch: {
    $route(to, from) {
      this.redirectionId = to.params.redirection_id
    },
  },
  mounted() {
    this.getRedirectionUrl()
  },
  methods: {
    getRedirectionUrl() {
      this.$http
        .get(`/api/redirection-link/${this.redirectionId}`)
        .then(response => {
          if (response.data.success) {
            window.location.href = response.data.link
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Redirection Link',
                  icon: 'AlertCircleIcon',
                  text: 'Your redirection link is invalid',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.$router.push('/')
          }
        })
        .catch(error => {
          this.$router.push('/')
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Redirection Link',
                icon: 'AlertCircleIcon',
                text: 'Your redirection link is invalid',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },
  },

}
</script>
